import store from "./vuex/index";

// 获取app设备信息
function getAppInfo() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi?.common?.handleEvent) {
      window.ingeekJSApi.common.handleEvent?.(
        { url: "app://event/getSystemInfo" },
        (res: any) => {
          if (res?.data) {
            console.log(res.data);
            store.commit("UPDATE_ENV", res.data);
          }
          resolve();
        }
      );
    } else {
      resolve();
    }
  });
}
async function intApp() {
  return new Promise<void>((resolve) => {
    if (window.ingeekJSApi) {
      window.ingeekJSApi.ready(async () => {
        try {
          await getAppInfo();
        } catch (err) {
          console.log(err);
        }
        resolve();
      });
    } else {
      resolve();
    }
  });
}
export { intApp };
