import { createWebViewLink } from "../utils/link";
import appEnv from "../utils/env";
const BACK_STATE_CACHE_KEY = "history_back_states";

/**
 * 获取当前的路由state
 * @returns
 */
function getHistoryState() {
  return window.history.state ? window.history.state.key || "" : "";
}

const Env = appEnv;
let initOptions;

let _Vue;

/**
 * 安装
 * @param {*} Vue
 * @param {*} param1
 */
function install(Vue, options = {}) {
  initOptions = options;
  _Vue = Vue;
  const router = options.router;
  const store = options.store;
  if (router && store) {
    init({
      $router: router,
      $store: store,
    });
  }
}

let _inited = false;

/**
 * 初始化
 * @param {*} app
 */
function init(app) {
  if (_inited) {
    return;
  }
  _inited = true;
  const store = app.$store;
  const router = app.$router;
  const newWebViewOptions = initOptions.newWebViewOptions || {};
  const location = window.location;
  const baseUrl = `${location.protocol}//${location.host}`;
  const originalPush = router.push;
  const originalReplace = router.replace;
  router.internalRoute = true; // 是否是内部导航（由router的api触发）
  router.latestInternalRoute = true;
  router.latestPushRoute = null;
  router.latestIsPush = false;
  router.latestStateKey = "";
  router.historyLength = window.history.length;
  router.isPush = 0;
  router.isReplace = false;
  /**
   * 是否使用新webview打开
   */
  router.replace = function () {
    router.isReplace = true;
    return originalReplace.apply(this, arguments);
  };
  const canUseWebRouter = Env.canUseWebRouter;
  router.currentInteractivePop = 1;
  router.push = function push(location, onResolve, onReject) {
    router.internalRoute = true;
    router.isPush = 1;
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
    const response = originalPush.call(this, location);
    if (typeof response !== "undefined" && response && response.catch) {
      return response.catch((err) => err);
    }
  };
  let firstRouteState = -1;
  let firstLoad = true;
  router.latestFromRoute = null; // 上一个route
  router.latestForwardFromRoute = null; // 上一个前进的route
  let backStates = window.sessionStorage.getItem(BACK_STATE_CACHE_KEY) || []; // 返回的state记录，用于判断前进后退
  router.afterEach((to, from) => {
    if (firstLoad) {
      // 首次进入
      router.isPush = 1;
    }
    if (router.isPush === 0) {
      // 浏览器触发的前进后退，此时 stateKey 已经变化
      const stateKey = getHistoryState();
      const forwardIndex = backStates.indexOf(stateKey);
      router.isPush = forwardIndex < 0 ? -1 : 1;
    }
    if (router.isPush > 0) {
      router.latestPushRoute = to;
    }
    router.latestIsPush = router.isPush > 0;
    if (!firstLoad || !router.latestFromRoute) {
      router.latestFromRoute = from;
      if (router.latestIsPush) {
        router.latestForwardFromRoute = from;
      } else {
        router.latestForwardFromRoute = null;
      }
    }
    _Vue.nextTick(() => {
      router.historyLength = window.history.length;
      // 重新获取一次最新的state，如果是router内部api触发的导航变化，只能在下一个循环里获取到变化的state
      const stateKey = getHistoryState();
      if (router.isPush > 0) {
        if (!firstLoad) {
          const forwardIndex = backStates.indexOf(stateKey);
          backStates = backStates.slice(0, Math.max(forwardIndex, 0));
        }
      } else {
        backStates.push(router.latestStateKey);
      }
      window.sessionStorage.setItem(BACK_STATE_CACHE_KEY, backStates);
      // 重置相关标识
      router.isPush = 0;
      router.isReplace = false;
      if (firstRouteState == -1 || window.history.length === 1) {
        firstRouteState = stateKey;
      }
      router.latestStateKey = stateKey;
      firstLoad = false;
    });
  });
  const originalGo = router.go;
  router.go = function (n) {
    router.internalRoute = true;
    if (n > 0) {
      router.isPush = 1;
    } else if (n < 0) {
      router.isPush = -1;
    }
    originalGo.call(this, n);
  };
  router.forceUseNewWebView = false;
  const storage = window.localStorage;
  let storageAvaiable = true;
  const passStoreKey = `passStore_${router.options.base}`;
  const passFromRouteKey = `passFromRoute_${router.options.base}`;
  router.latestWindowScrollTop = 0;
  router.latestWindowScrollHeight = 0;

  const beforeResolve = router.beforeResolve || router.beforeEach;
  beforeResolve.bind(router)((to, from, next) => {
    const useNewWebView = canUseWebRouter;
    // if (mmbUtil.env.isClient) {
    //   router.latestWindowScrollTop = mmbUtil.dom.getScrollY();
    //   router.latestWindowScrollHeight = Math.max(
    //     document.documentElement.scrollHeight || 0,
    //     document.body.scrollHeight
    //   );
    // }
    if (router.isPush > 0) {
      if (useNewWebView) {
        router.isPush = 0;
        const passStore = to.meta.passStore || false;
        if (passStore && storageAvaiable) {
          // 将store的值缓存到storage，在下一个页面进行恢复
          storage.setItem(passStoreKey, JSON.stringify(store.state));
        }
        // 将fromRoute 序列化，在下一个页面恢复
        console.error(to);
        if (from) {
          storage.setItem(
            passFromRouteKey,
            JSON.stringify({
              fullPath: from.fullPath,
              hash: from.hash,
              meta: from.meta,
              name: from.name,
              params: from.params,
              path: from.path,
              query: from.query,
            })
          );
        }
        router.pushNewWebView(to);
        router.internalRoute = false;
        next(false);
        return;
      }
    }
    router.latestInternalRoute = router.internalRoute;
    router.internalRoute = false;
    next();
  });
  /**
   * 打开新的webView，只在app内生效
   */
  router.pushNewWebView = function (to) {
    if (Env.canUseWebRouter) {
      let webViewOptions;
      if (typeof to === "string") {
        webViewOptions = { url: to };
      } else if (to.url) {
        // eslint-disable-next-line no-prototype-builtins
        if (!Object.prototype.hasOwnProperty(to, "native")) {
          to.native = 1;
        }
        webViewOptions = to;
      } else {
        const meta = to.meta || {};
        const _webViewOptions = meta.webViewOptions || {};
        let fullUrl = "";
        if (router.mode === "hash") {
          fullUrl = `${location.pathname}${location.search}#`;
        } else {
          fullUrl = `${router.options.base}`;
        }
        fullUrl = `${fullUrl}${to.fullPath}`.replace("//", "/");
        webViewOptions = Object.assign(
          {
            url: baseUrl + fullUrl,
            meta: to?.meta,
          },
          newWebViewOptions,
          _webViewOptions
        );
      }
      window.location.href = createWebViewLink("", webViewOptions);
      return true;
    }
    return false;
  };
  _Vue.nextTick(() => {
    let passedStore = storage.getItem(passStoreKey);
    if (passedStore) {
      const passedStoreObj = JSON.parse(passedStore);
      delete passedStoreObj.mmb;
      store.replaceState(Object.assign({}, store.state, passedStoreObj));
      storage.removeItem(passStoreKey);
    }
    const passedFromRoute = storage.getItem(passFromRouteKey);
    if (passedFromRoute) {
      router.latestFromRoute = JSON.parse(passedFromRoute);
      console.error(router.latestFromRoute);
      router.latestForwardFromRoute = router.latestFromRoute;
      storage.removeItem(passFromRouteKey);
    }
  });
}

export default {
  install,
  init,
};
