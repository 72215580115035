<template>
  <div class="appWebsite">
    <mt-header fixed title="用户说明" class="header"> </mt-header>
    <mt-tab-container v-model="active" class="container">
      <mt-tab-container-item id="001">
        <div
          class="row"
          v-for="(item, index) in imglist"
          :key="index"
          @click="checkVideo(item.title)"
        >
          <img :src="item.iconSrc" />
          <div class="dim-area">
            <p class="label-area">
              <span class="label-title">{{ item.title }}</span>
              <span class="label-content">{{ item.content }}</span>
            </p>
          </div>
        </div>
      </mt-tab-container-item>
      <mt-tab-container-item id="002">
        <div></div>
      </mt-tab-container-item>
      <mt-tab-container-item id="003">
        <div></div>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  watch: {},

  computed: {},
  data() {
    return {
      active: "001",
      selected: "001",
      imglist: [
        {
          title: "车辆连接",
          iconSrc:
            "https://xplan-public-file.oss-cn-shanghai.aliyuncs.com/web/hybrid/t1.png",
          content: "首次使用如何与车辆建立联系",
        },
        {
          title: "车辆解锁",
          iconSrc:
            "https://xplan-public-file.oss-cn-shanghai.aliyuncs.com/web/hybrid/t2.png",
          content: "无需掏出手机，靠近自动解锁",
        },
        {
          title: "智能车控",
          iconSrc:
            "https://xplan-public-file.oss-cn-shanghai.aliyuncs.com/web/hybrid/t3.png",
          content: "如何用手机操控后背厢，车窗等设备开关",
        },
        {
          title: "钥匙分享",
          iconSrc:
            "https://xplan-public-file.oss-cn-shanghai.aliyuncs.com/web/hybrid/t4.png",
          content: "如何把钥匙使用权分享给好友",
        },
        {
          title: "过户",
          iconSrc:
            "https://xplan-public-file.oss-cn-shanghai.aliyuncs.com/web/hybrid/t5.png",
          content: "车辆过户，数字钥匙怎么处理",
        },
      ],
    };
  },
  components: {},
  created() {
    console.info(this.$route);
    console.info(this.$router);
    document.title = "用户手册";
  },
  methods: {
    tabChanged(id) {
      this.active = id;
    },
    checkVideo(title) {
      this.$router.push({
        name: "appWebsite-showVideo",
        params: {
          title: title,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.appWebsite {
  overflow: hidden;
  .header {
    height: 44pt;
    line-height: 44pt;
    font-size: 4.53vw;
    background-color: #fff;
    color: #000;
    display: none;
  }
  .container {
    /*margin-top:44pt;*/
    padding: 0 2.67vw;
    padding-top: 0;
    padding-bottom: 4.8vw;
    box-sizing: border-box;
    /* height: calc(100vh - 6.25rem);
   overflow: auto;*/
    .row {
      width: 100%;
      position: relative;
      margin-top: 2.67vw;
      img {
        display: block;
        width: 100%;
      }
      .dim-area {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        .label-area {
          position: absolute;
          bottom: 5.33vw;
          left: 5.33vw;
          color: #fff;
          .label-title {
            font-size: 4.8vw;
            height: 6.67vw;
            font-weight: 200;
            display: block;
            padding: 0;
            line-height: 6.67vw;
          }
          .label-content {
            font-size: 3.2vw;
            height: 4.53vw;
            line-height: 4.53vw;
            margin-top: 1.6vw;
            display: block;
          }
        }
      }
    }
  }
  .footer {
    display: none;
    height: 3.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
</style>
